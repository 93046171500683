import React, { Component } from "react";
import { withAuthenticationRequired, withAuth0 } from "@auth0/auth0-react";
import axios, { initSocketIO } from "common/ServerConnection";
import { goToInternalLink } from "common/InternalLinksHelper";
import * as Sentry from "@sentry/react";
import CurrentUser from "common/CurrentUser";

const ENV_DEV = "development"
const ENV_RELEASE = "release"
const ENV_PROD = "production"
const ENV_SUBPROD = "subproduction"
const ENV_LOCALHOST = "localhost"

let env = window.location.host.startsWith("dev")
    ? ENV_DEV
    : window.location.host.startsWith("release") 
    ? ENV_RELEASE 
    : window.location.host.startsWith("appb")
    ? ENV_SUBPROD
    : window.location.host.startsWith("app")
    ? ENV_PROD
    : ENV_LOCALHOST;

let tracesSampleRateConfig = {
    ENV_DEV: 1,
    ENV_RELEASE: 0.5,
    ENV_SUBPROD: 0.5,
    ENV_PROD: 0.05,
    ENV_LOCALHOST: 1
}

Sentry.init({    
    dsn: "https://d247c9fc96d4477a9b1779d0b2594b92@o1115498.ingest.sentry.io/4505092472307712",
    environment: env,    
    integrations: [
        /*
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
      }), 
      */
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: tracesSampleRateConfig[env], // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

class Auth0Wrapper extends Component {
    constructor(props) {
        super(props);
        const { getAccessTokenSilently, logout } = this.props.auth0;
        this.state = {
            accessTokenIsFetched: false,
        };
        this.getAccessTokenSilently = getAccessTokenSilently;
        this.logout = logout;
        this.app = undefined;
    }

    static redirectOnTokenExpired(error) {
        if (!window.navigator.onLine && error.response == null) {
            return Promise.reject(new Error("No internet connection"));
        } else if (error.response?.status === 401) {
            goToInternalLink("/");
            // Prevent execution of .then() and .catch()
            return new Promise(() => {});
        } else {
            return Promise.reject(error);
        }
    }

    async componentDidMount() {
        if (this.props.auth0.isAuthenticated) {
            this.initialize();
        }
    }
    async componentDidUpdate(prevProps) {
        if (
            !prevProps.auth0.isAuthenticated &&
            this.props.auth0.isAuthenticated
        ) {
            this.initialize();
        }
        if (!this.props.auth0.isAuthenticated && !this.props.auth0.isLoading) {
            if (
                window.location.pathname === "/" ||
                window.location.pathname === ""
            ) {
                goToInternalLink("/login.html");
            } else {
                const after = {
                    pathname: window.location.pathname,
                    search: decodeURIComponent(window.location.search),
                };
                let afterString = JSON.stringify(after);
                goToInternalLink(
                    `/${encodeURIComponent(
                        afterString
                    )}`
                );
            }
        }
    }

    initialize() {
        if (window.location.pathname === "/redirect.html") {
            const urlParams = new URLSearchParams(window.location.search);
            let after = urlParams.get("after");
            if (after) {
                const afterJson = JSON.parse(after);
                const afterPathName = afterJson.pathname;
                if (afterPathName === "/redirect.html") {
                    goToInternalLink("/");
                    return;
                }
                let afterSearch = afterJson.search;
                let afterSearchParams = "";
                if (afterSearch && afterSearch[0] === "?") {
                    afterSearch = afterSearch.slice(1);
                    const params = afterSearch.split("&");
                    const urlSearchParams = new URLSearchParams();
                    for (let param of params) {
                        const keyValue = param.split("=");
                        if (keyValue.length === 2) {
                            urlSearchParams.append(keyValue[0], keyValue[1]);
                        }
                    }
                    afterSearchParams = urlSearchParams.toString();
                    if (afterSearchParams.length > 0) {
                        afterSearchParams = "?" + afterSearchParams;
                    }
                }
                const redirectUri = `${afterPathName}${afterSearchParams}`;
                goToInternalLink(redirectUri);
                return;
            }
        }
        axios.interceptors.response.use(
            undefined,
            Auth0Wrapper.redirectOnTokenExpired
        );

        this.getAccessTokenSilently()
            .then((accessToken) => {
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${accessToken}`;
                initSocketIO(accessToken);
                // HACK to force webpack build these modules
                if (this.props.modulePath === "App")
                    this.app = require("App")[
                        this.props.moduleName ?? "default"
                    ];
                if (
                    this.props.modulePath ===
                    "main_pages/CanvasModuleSharedPage"
                )
                    this.app = require("main_pages/CanvasModuleSharedPage")[
                        this.props.moduleName ?? "default"
                    ];
                if (this.props.modulePath === "main_pages/SharedKitPage")
                    this.app = require("main_pages/SharedKitPage")[
                        this.props.moduleName ?? "default"
                    ];
                this.setState({ accessTokenIsFetched: true });
                let username = this.props.auth0.user.sub;
                username = username.split("|").pop();
                window.localStorage.setItem("username", username);
                CurrentUser.update(false);
            })
            .catch((error) => {
                console.log(error);
            });

        // cheat auth0 token idle timeout expiration
        var seconds_to_refresh = 3600;
        setInterval(
            () => this.getAccessTokenSilently({ ignoreCache: true }),
            seconds_to_refresh * 1000
        );
    }

    render() {
        if (!this.state.accessTokenIsFetched) {
            return <div>Loading...</div>;
        }
        var redirectTo = window.location.origin + "/";
        return (
            <this.app
                auth0Logout={this.logout}
                auth0LogoutRedirectTo={redirectTo}
                getAccessTokenSilently={this.getAccessTokenSilently}
                {...this.props.moduleProps}
            />
        );
    }
}

export default withAuthenticationRequired(withAuth0(Auth0Wrapper), {
    onRedirecting: () => <div>Loading...</div>,
});

