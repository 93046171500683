import React from "react";
import HTMLSlider from "./HTMLSlider";
import HTMLToggle from "./HTMLToggle";
import HTMLInput from "./HTMLInput";
import HTMLFlowchart from "./HTMLFlowchart";
import BarcodeReader from "./BarcodeReader";
import Survey from "./Survey";
import ProgressElement from "./ProgressElement";
import CanvasTreeStore from "../CanvasTreeStore";

import {
    CanvasNode,
    isToggle,
    isInput,
    isSlider,
    isSubmitButton,
    isBox,
    isBarcodeReader,
    isSurvey,
    isProgressElement,
    isRadioButtonsGroup,
    CanvasSurvey,
    CanvasElement,
    CanvasSubmitButton,
    CanvasInput,
} from "common/Canvas";
import { defaultInputFieldPlaceholder } from "../Constants";
import HTMLRadioButtonsGroup from "./RadioButtonsGroup/HTMLRadioButtonsGroup";
import { getRawDataApi } from "common/DataApi";
import PopupData from "common/PopupData";

interface Props {
    canvasTreeStore: CanvasTreeStore;
    layerRect?: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    scale: number;
    node: CanvasInput;
    fontColor: string;
    frozen?: boolean;
    live: boolean;
    fontSize: number;
    onMetricChanged: (metric: string) => void;
    width: number;
    height: number;
    onDblClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onTouchStart?: (event: React.TouchEvent<HTMLDivElement>) => void;
    currentModuleId: number | undefined;
    rootDataTestId: string;
    hovered: boolean;
    onExpandCard: (node: CanvasElement | CanvasSubmitButton | CanvasSurvey) => void;
}

export default function InputView(props: Props): JSX.Element | null {
    React.useEffect(() => {
        if(props.node.update?.active) {
            getTableData();
        }
    }, []);

    const getTableData = () => {
        if(props.node.update?.active && props.node.update.contextId) {
            getRawDataApi(
                {
                    label: "",
                    value: [],
                    optimized: false,
                    data_table_idx: (props.node as any).dataScopeOption?.value,
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                PopupData.data?.row_id ?[PopupData.data?.row_id] : undefined,
                true
            ).then((res) => {
                if(props.node.variableOption?.label)
                    props.canvasTreeStore.updateNodeAction(
                        props.node.id, {
                            metric: res.currentLevels[props.node.variableOption?.label][0] as string ?? ''
                        }
                    );
            })
        }
    }
    if (isRadioButtonsGroup(props.node)) {
        return (
            <HTMLRadioButtonsGroup
                canvasTreeStore={props.canvasTreeStore}
                rootDataTestId={props.rootDataTestId}
                scale={props.scale}
                node={props.node}
                currentModuleId={props.currentModuleId}
                onMetricChanged={props.onMetricChanged}
            />
        );
    }
    if (isBox(props.node)) {
        return (
            <HTMLFlowchart
                rootDataTestId={props.rootDataTestId}
                fontColor={props.fontColor}
                node={props.node}
                scale={props.scale}
                live={props.live}
                onDblClick={props.onDblClick}
                onTouchStart={props.onTouchStart}
            />
        );
    }
    if (isSlider(props.node)) {
        let formattedValue = CanvasTreeStore.getInputValue(props.node, false);
        return (
            <HTMLSlider
            rootDataTestId={props.rootDataTestId}
                fontColor={props.fontColor}
                fontSize={props.fontSize}
                formattedValue={formattedValue}
                node={props.node}
                scale={props.scale}
                onMetricChanged={props.onMetricChanged}
            />
        );
    }
    if (isToggle(props.node))
        return (
            <HTMLToggle
                rootDataTestId={props.rootDataTestId}
                frozen={props.frozen ?? false}
                node={props.node}
                width={props.width}
                height={props.height}
                onMetricChanged={props.onMetricChanged}
                scale={props.scale}
                fontColor={props.fontColor}
                fontSize={props.fontSize}
            />
        );
    if (
        isInput(props.node) ||
        isSubmitButton(props.node)
    ) {
        let formattedValue: string = "";
        if (isInput(props.node)) {
            formattedValue = CanvasTreeStore.getInputValue(
                props.node,
                props.live
            );
        } else if (isSubmitButton(props.node)) {
            formattedValue = (props.node as CanvasNode).metric || defaultInputFieldPlaceholder;
        }
        
        return (
            <HTMLInput
                rootDataTestId={props.rootDataTestId}
                fontColor={props.fontColor}
                fontSize={props.fontSize}
                formattedValue={formattedValue}
                node={props.node}
                hovered={props.hovered}
                scale={props.scale}
                onDblClick={props.onDblClick}
                onTouchStart={props.onTouchStart}
            />
        );
    }
    if (isSurvey(props.node)) {
        return (
            <Survey
                onExpandCard={props.onExpandCard}
                rootDataTestId={props.rootDataTestId}
                canvasTreeStore={props.canvasTreeStore}
                node={props.node}
                live={props.live}
                currentModuleId={props.currentModuleId}
            />
        );
    }
    if (isBarcodeReader(props.node)) {
        return (
            <BarcodeReader
                rootDataTestId={props.rootDataTestId}
                canvasTreeStore={props.canvasTreeStore}
                node={props.node}
                live={props.live}
                currentModuleId={props.currentModuleId}
            />
        );
    }
    if (isProgressElement(props.node)) {
        return (
            <ProgressElement
                rootDataTestId={props.rootDataTestId}
                fontColor={props.fontColor}
                fontSize={props.fontSize}
                node={props.node}
                scale={props.scale}
                canvasTreeStore={props.canvasTreeStore}
            />
        );
    }
    return null;
}

