import React, { Component } from "react";
import { observer } from "mobx-react";
import Popup from "reactjs-popup";
import { BriefCanvas, SharedModuleCanvas, SlideTemplate } from "common/Canvas";
import CanvasContent from "./CanvasContent";
import CanvasTreeStore from "./CanvasTreeStore";
import CanvasSharedPolicy from "common/CanvasSharedPolicy";
import Canvases from "common/Canvases";
import { SharedModule } from "common/Module";

interface Props {
    inPopup?: boolean;
    allowCache: boolean;
    windowSize: {
        width: number;
        height: number;
    };
    containerStyle?: React.CSSProperties;
    sharedModule?: SharedModule;
    canvas: BriefCanvas | SharedModuleCanvas | SlideTemplate;
    moduleId?: number | string;
    slideContainerClassName?: string;
    requireAuthentication?: boolean;
    onDblClick?: () => void;
    onClose?: () => void;
}

@observer
class LinkSlidePopup extends Component<Props> {
    private canvasTreeStore = new CanvasTreeStore(true);
    
    constructor(props: Props) {
        super(props);
        this.onWindowDblClick = this.onWindowDblClick.bind(this);
        this.changeScale = this.changeScale.bind(this);
    }

    initialSize() {
        let inPopup = this.props.inPopup ?? true;
        if (!inPopup)
            return {
                width: this.props.windowSize.width,
                height: this.props.windowSize.height,
            };
        return {
            width: this.props.windowSize.width * 0.8 - 30,
            height: this.props.windowSize.height * 0.8 - 30,
        };
    }

    public async componentDidMount() {
        this.canvasTreeStore.setInitialSize(this.initialSize());
        if (this.props.sharedModule == null) {
            let canvas = this.props.canvas as BriefCanvas;
            let fetchedCanvas = await Canvases(canvas.page_id).getFullCanvas(
                canvas.id,
                this.props.allowCache
            );
            this.canvasTreeStore.moduleId = this.props.moduleId as number;
            this.canvasTreeStore.canvasPageId = canvas.page_id;
            await this.canvasTreeStore.deserializeAsyncAction.bothParts(
                fetchedCanvas.canvas,
                {
                    canvasId: fetchedCanvas.id,
                    backgrounds: fetchedCanvas.backgrounds,
                    delegateId: fetchedCanvas.delegate_id,
                }
            );
        } else {
            let canvas = this.props.canvas as SharedModuleCanvas;
            this.canvasTreeStore.canvasPageId = canvas.page_id;

            this.canvasTreeStore.deserializeFromSharedModuleAsyncAction.bothParts(
                canvas,
                this.props.sharedModule,
                this.props.requireAuthentication!
            );
            this.canvasTreeStore.canvasId = canvas.id;
            this.canvasTreeStore.isLoadingState = false;
        }
        this.canvasTreeStore.calculateFitScale(true, false, false);
    }
    componentDidUpdate(prevProps: Props) {
        let windowSize = prevProps.windowSize;
        if (
            windowSize.height !== this.props.windowSize.height ||
            windowSize.width !== this.props.windowSize.width
        ) {
            this.canvasTreeStore.setInitialSize(this.initialSize());
            this.canvasTreeStore.calculateFitScale(true, false, false);
        }
    }

    private onWindowDblClick(event: React.MouseEvent<HTMLElement>): void {
        event.preventDefault();
        if (this.props.onDblClick != null) {
            this.props.onDblClick();
        }
    }
    private changeScale(scale: number) {
        this.canvasTreeStore.scale = scale;
    }

    public render(): JSX.Element {
        let innerComponent = (
            <div
                className="dashboard-rect"
                style={{
                    boxShadow: "0px 6px 13px rgba(21, 33, 56, 0.533345)",
                    borderRadius: 10,
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    maxHeight: "100vh",
                    padding: "15px",
                    ...this.props.containerStyle,
                }}
                // onDoubleClick={this.onWindowDblClick}
            >
                <div
                    className="element"
                    style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        overflow: "hidden auto",
                    }}
                    onKeyDown={(evt) => {
                        evt.stopPropagation();
                    }}
                >
                    <CanvasContent
                        slideContainerClassName={
                            this.props.slideContainerClassName
                        }
                        canvasTreeStore={this.canvasTreeStore}
                        showAsSlideShow={false}
                        ribbonIsOpen={false}
                        sharedPolicy={CanvasSharedPolicy.SharedModuleUnAuth}
                        onZoomChange={this.changeScale}
                        moduleId={
                            typeof this.props.moduleId === "number"
                                ? this.props.moduleId
                                : undefined
                        }
                        onOpenBottomPortal={() => {}}
                        hidden={this.canvasTreeStore.isLoadingState}
                        canWrite={true}
                        scale={this.canvasTreeStore.scale}
                        live={true}
                        selectionIsActive={false}
                        showPageBar={false}
                        sharedModule={undefined}
                        onExpandCard={() => {}}
                        onExpandUserCard={() => {}}
                        onOpenThemes={() => {}}
                        hideHeaderBar={() => {}}
                        onScrollToTop={() => {}}
                        pages={[]}
                        rootSectionRef={undefined}
                        headerBarIsOpened={false}
                        pageBarInfo={undefined}
                        switchCanvasView={() => {}}
                        onShowGuideDots={() => {}}
                        onDashboardEditMenuIsOpened={() => {}}
                        showDots={false}
                        showSlideNumbers={false}
                        isLiveStreaming={false}
                        captureCanvas={false}
                        onCloseCapture={() => {}}
                        onFinishCapture={() => {}}
                        pinInitializer={undefined}
                        moduleTitle=""
                        tablePreviewVisible={false}
                        disableZoomWheel={false}
                        dashboardEditMenuIsOpened={false}
                        setCurrentEditId={() => {}}
                    />
                </div>
            </div>
        );
        return this.props.inPopup ?? true ? (
            <Popup
                arrow={true}
                contentStyle={{
                    border: "none",
                    backgroundColor: "transparent",
                    width: "80vw",
                    height: "80vh",
                }}
                open={true}
                onClose={this.props.onClose}
            >
                {innerComponent}
            </Popup>
        ) : (
            innerComponent
        );
    }
}

export default LinkSlidePopup;
