export default function checkIsSharedPage() {
    let pathName = window.location.pathname;
    if (pathName === "/shared_module.html") {
        return true;
    }
    if (
        pathName === "/shared_auth_canvas_module.html" ||
        pathName.startsWith("/app/") || (pathName.startsWith("/redirect") && window.location.href.includes("app"))
    ) {
        return true;
    }
    if (pathName === "/tutorial.html") {
        return true;
    }
    if (pathName === "/shared_canvas_module.html") {
        return true;
    }
    if (pathName === "/reset_password.html") {
        return true;
    }
    if (pathName === "/register.html") {
        return true;
    }
    if (pathName === "/login.html") {
        return true;
    }
    if (pathName === "/forgot_password.html") {
        return true;
    }
    return false;
}
