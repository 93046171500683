import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { DndProvider } from "react-dnd"; // Drag and Drop for React
import { HTML5Backend } from "react-dnd-html5-backend"; // Drag and Drop for React Backend
import IdleTimer from "react-idle-timer"; // Checks to see if the page has been idle or not.
import MessagePopup from "common/MessagePopup";
import Alert from "common/Alert";
import ls from "local-storage";
import "common/styles/App.css";
import axios from "common/ServerConnection"; // This connects to the backend for the requests.
import FindingHandler from "common/FindingHandler";
// import HeaderBar from "common/HeaderBar";
import HeaderBarRevamped from "common/HeaderBarRevamped";
import Instrumentation, { InstrumentationStatus } from "common/Instrumentation";
import TermsOfServiceModal from "common/TermsOfServiceModal";
// import NavigationMenu from "common/NavigationMenu";
import PasswordChangePopup from "common/PasswordChangePopup.tsx";
import { SocketIOInstance } from "common/ServerConnection";
import {
    goToInternalLink,
    getInternalLinkActive,
} from "common/InternalLinksHelper";
import GlobalContext from "GlobalContext";

import modules from "modules/module_list.json";
import {
    registerUserNotifications,
    unRegisterUserNotifications,
} from "common/UserNotifications";
import StoreContext, { store } from "state/StoreContext";
import { visibilityChangeProps } from "common/utilities/VisibilityChange";
import TosContext, { TosProvider } from "contexts/TosContext";
import Page404 from "main_pages/Page404";
import sections from "sections.json";

const visibilityProps = visibilityChangeProps(document);

class SectionVisit extends Component {
    componentDidMount() {
        Instrumentation.addSectionVisit(this.props.section);
    }

    render() {
        return React.Children.only(this.props.children);
    }
}

const AppWrapper = (props) => {
    return (
        <TosProvider>
            <App {...props} />
        </TosProvider>
    );
};

class App extends Component {
    static contextType = TosContext;

    constructor(props) {
        super(props);
        this.state = {
            leftDashboardOptions: [],
            routes: [],
            routesToSectionNames: {},
            navigationMenuExpanded: false,
            navigationMenuHidden: false,
            logoutPopupOpen: false,
            logoutPopupTimeout: 75, // minutes
            passwordExpired: false,
            versionsAreNotMatching: false,
            context: {
                permissions: {},
            },
        };
        this.idleTimerRef = React.createRef();
        this.rootRef = React.createRef();
        this.onUnload = this.onUnload.bind(this);
        this.onToggleClicked = this.onToggleClicked.bind(this);
        this.onLiveModeChanged = this.onLiveModeChanged.bind(this);
        this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
        this.onWindowFocusChanged = this.onWindowFocusChanged.bind(this);
        this.windowIsFocused = true;
        this.lastInstrumentationStatus = InstrumentationStatus.Inactive;
        this.acceptTermsOfService = this.acceptTermsOfService.bind(this);
        this.logoutTimeout = null;
        this.initialVisibilityChanged = false;
        this.auth0Logout = props.auth0Logout;
        this.auth0LogoutRedirectTo = props.auth0LogoutRedirectTo;
        this.checkAppVersionInterval = null;
    }

    // Add onToggleClicked()
    onToggleClicked(expanded) {
        this.setState({ navigationMenuExpanded: expanded });
    }
    onVisibilityChanged() {
        if (getInternalLinkActive()) return;
        if (document[visibilityProps.hidden] || !this.windowIsFocused) {
            if (
                this.lastInstrumentationStatus !==
                InstrumentationStatus.Inactive
            ) {
                Instrumentation.setStatus(InstrumentationStatus.Inactive);
                this.lastInstrumentationStatus = InstrumentationStatus.Inactive;
            }
        } else {
            if (
                this.lastInstrumentationStatus !== InstrumentationStatus.Active
            ) {
                Instrumentation.setStatus(InstrumentationStatus.Active);
                this.lastInstrumentationStatus = InstrumentationStatus.Active;
            }
        }
    }
    onWindowFocusChanged(event) {
        this.windowIsFocused = event.type === "focus";
        this.onVisibilityChanged();
    }
    onLiveModeChanged(live) {
        // If API has loaded
        if (window.Tawk_API?.hideWidget != null) {
            if (live) {
                window.Tawk_API.hideWidget();
            } else {
                window.Tawk_API.showWidget();
            }
        }
        this.setState({ navigationMenuHidden: live });
    }
    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload);

        if (visibilityProps.supported) {
            window.addEventListener("blur", this.onWindowFocusChanged);
            window.addEventListener("focus", this.onWindowFocusChanged);
            document.addEventListener(
                visibilityProps.visibilityChange,
                this.onVisibilityChanged,
                false
            );
        }
        const now = new Date();
        let millisTill10 =
            new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                10,
                0,
                0,
                0
            ) - now;
        if (millisTill10 < 0) {
            millisTill10 += 86400000; // it's after 10am.
        }
        setTimeout(() => {
            axios
                .post("/api/get_app_version", {})
                .then((response) => {
                    if (response.data.success) {
                        if (response.data.success) {
                            const appVersion = sections._Global.version;
                            const { version } = response.data;
                            if (appVersion !== version) {
                                this.setState({
                                    ...this.state,
                                    versionsAreNotMatching: true,
                                });
                            }
                        }
                    } else {
                        console.log(response.data.error_msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }, millisTill10);

        axios
            .post("/api/get_logout_timeout", {})
            .then((response) => {
                if (response.data.success) {
                    if (response.data.logout_timeout != null) {
                        this.setState({
                            logoutPopupTimeout: Number(
                                response.data.logout_timeout
                            ),
                        });
                    }
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        axios
            .post("/api/check_password_expiration", {})
            .then((response) => {
                if (response.data.success) {
                    if (response.data.result != null) {
                        this.setState({
                            passwordExpired: response.data.result,
                        });
                    }
                } else {
                    console.log(response.data.error_msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        axios
            .post("/api/get_permissions_for_current_user", {})
            .then((response) => {
                if (!response.data.success) return;

                if (response.data.permissions != null) {
                    this.buildLeftDashboard(response.data.permissions);
                }
            });

        axios.get("/api/tos_check_user_accept").then((response) => {
            this.context.setAgreed(response.data.agree_flag);
        });

        // We do not need to catch error here since if this request results
        // in an error, then the frontend cannot function
        registerUserNotifications();
    }

    buildLeftDashboard(permissions) {
        let leftDashboardOptions = [];
        let routes = [];
        let routesToSectionNames = {};
        modules.forEach((dir) => {
            let mod = require(`./modules/${dir}/main`);
            if (
                mod.requirePermission == null ||
                mod.requirePermission in permissions
            ) {
                if (Object.prototype.hasOwnProperty.call(mod, "leftPanelItem"))
                    leftDashboardOptions.push({ root: mod.leftPanelItem });
                if (Object.prototype.hasOwnProperty.call(mod, "route"))
                    // We need section name for visit counts
                    routes.push([mod.route, mod.section, mod.MainComponent]);
                routesToSectionNames[mod.route] = mod.section;
            }
        });

        this.setState((state) => ({
            leftDashboardOptions: leftDashboardOptions,
            routes: routes,
            routesToSectionNames: routesToSectionNames,
            context: {
                ...state.context,
                permissions: permissions,
            },
        }));
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
        if (visibilityProps.supported) {
            window.removeEventListener("blur", this.onWindowFocusChanged);
            window.removeEventListener("focus", this.onWindowFocusChanged);
            document.removeEventListener(
                visibilityProps.visibilityChange,
                this.onVisibilityChanged
            );
        }
        unRegisterUserNotifications();
        clearInterval(this.checkAppVersionInterval);
    }
    onUnload(evt) {
        // SocketIOInstance?.emit("manual_disconnect", {});
        if (window.location.pathname === "/canvas.html") {
            return;
        }
        SocketIOInstance?.disconnect(true);
    }
    logout() {
        Instrumentation.close().then(() => {
            this.auth0Logout({ returnTo: this.auth0LogoutRedirectTo });
        });
    }
    acceptTermsOfService() {
        axios.post("/api/tos_save_agree", {}).then((response) => {
            if (response.data.success) {
                this.context.setAgreed(true);
            }
        });
    }
    render() {
        // Check visibility once
        if (!this.initialVisibilityChanged) {
            this.initialVisibilityChanged = true;
            this.onVisibilityChanged();
        }
        const location = window.location.pathname;
        // const headerTitle = this.state.routesToSectionNames[location];
        return (
            <GlobalContext.Provider value={this.state.context}>
                <StoreContext.Provider value={store}>
                    <DndProvider backend={HTML5Backend}>
                        <div className="wrapper" ref={this.rootRef}>
                            <IdleTimer
                                ref={this.idleTimerRef}
                                crossTab={{
                                    emitOnAllTabs: true,
                                }}
                                timeout={Math.min(
                                    1000 * 60 * this.state.logoutPopupTimeout,
                                    2147483647
                                )}
                                onIdle={() => {
                                    // The timer has to be paused to prevent
                                    // the popup from closing when the user moves
                                    // the mouse cursor
                                    this.idleTimerRef.current?.pause();
                                    this.setState(
                                        (state) => {
                                            if (!state.passwordExpired) {
                                                return {
                                                    logoutPopupOpen: true,
                                                };
                                            } else {
                                                return {};
                                            }
                                        },
                                        () => {
                                            if (this.state.logoutPopupOpen) {
                                                this.logoutTimeout = setTimeout(
                                                    () => {
                                                        this.logout();
                                                    },
                                                    1000 * 60 * 1
                                                );
                                            }
                                        }
                                    );
                                }}
                                onActive={() => {
                                    if (this.logoutTimeout != null) {
                                        clearTimeout(this.logoutTimeout);
                                    }
                                    this.setState({
                                        logoutPopupOpen: false,
                                    });
                                }}
                                debounce={250}
                            >
                                {this.state.passwordExpired && (
                                    <PasswordChangePopup
                                        onClose={() => {
                                            this.setState({
                                                passwordExpired: false,
                                            });
                                        }}
                                        mandatory
                                    />
                                )}
                                {this.state.logoutPopupOpen && (
                                    <MessagePopup
                                        title={"Logout"}
                                        message={
                                            "You will be logged out in 1 minute due to being idle."
                                        }
                                        acceptButtonTitle="Logout"
                                        onAccept={() => {
                                            Instrumentation.close().then(() => {
                                                goToInternalLink(
                                                    "/logout.html"
                                                );
                                            });
                                        }}
                                        onReject={() => {
                                            // We need to reset the timer
                                            // since it was paused previously.
                                            // This will also close the popup in all
                                            // other tabs.
                                            this.idleTimerRef.current?.reset();
                                            clearTimeout(this.logoutTimeout);
                                            this.setState({
                                                logoutPopupOpen: false,
                                            });
                                        }}
                                    />
                                )}
                                {location !== "/logout.html" && (
                                    <TermsOfServiceModal
                                        show={
                                            !this.state.passwordExpired &&
                                            !this.context.agreed
                                        }
                                        cancel={this.logout}
                                        confirm={this.acceptTermsOfService}
                                    />
                                )}
                                {/*location !== "/canvas.html" &&
                                !isHomePage(location) && (
                                    <HeaderBar
                                        title={headerTitle}
                                        onToggleClicked={this.onToggleClicked}
                                    />
                                )*/}
                                {location !== "/canvas.html" && (
                                    <HeaderBarRevamped
                                        routes={this.state.leftDashboardOptions}
                                    />
                                )}
                                {/*location !== "/canvas.html" &&
                                !this.state.navigationMenuHidden &&
                                !isHomePage(location) && (
                                    <NavigationMenu
                                        rootRef={this.rootRef}
                                        content={
                                            this.state.leftDashboardOptions
                                        }
                                        expanded={
                                            this.state.navigationMenuExpanded
                                        }
                                    />
                                )*/}
                                {this.state.versionsAreNotMatching && (
                                    <Alert
                                        text={
                                            "We have updated Eisengard -- please refresh"
                                        }
                                        className="alert alert-warning alert-dismissible"
                                        style={{
                                            position: "absolute",
                                            top: 60,
                                            left: "calc(50% - 200px)",
                                            zIndex: 9,
                                        }}
                                        onClosed={() =>
                                            this.setState({
                                                versionsAreNotMatching: false,
                                            })
                                        }
                                    />
                                )}
                                <main className="main-wrapper">
                                    <BrowserRouter>
                                        <Switch>
                                            {this.state.routes.length > 0 &&
                                                this.state.routes[0] && (
                                                    <Route
                                                        exact
                                                        path="/"
                                                        render={() => {
                                                            return (
                                                                <Redirect
                                                                    to={
                                                                        this
                                                                            .state
                                                                            .routes[0][0]
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    />
                                                )}
                                            {this.state.routes.length > 0 &&
                                                this.state.routes[0] && (
                                                    <Route
                                                        exact
                                                        path="/index.html"
                                                        render={() => {
                                                            return (
                                                                <Redirect
                                                                    to={
                                                                        this
                                                                            .state
                                                                            .routes[0][0]
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    />
                                                )}

                                            <Route
                                                exact
                                                path="/logout.html"
                                                render={() => {
                                                    console.log("logout");
                                                    this.auth0Logout({
                                                        returnTo: this
                                                            .auth0LogoutRedirectTo,
                                                    });
                                                }}
                                            />

                                            {this.state.routes.map(
                                                (value, index) => {
                                                    const [
                                                        route,
                                                        section,
                                                        MainComponent,
                                                    ] = value;
                                                    return (
                                                        <Route
                                                            exact
                                                            key={index}
                                                            path={route}
                                                            render={() => {
                                                                return (
                                                                    <SectionVisit
                                                                        section={
                                                                            section
                                                                        }
                                                                    >
                                                                        <FindingHandler>
                                                                            <MainComponent
                                                                                routes={
                                                                                    this
                                                                                        .state
                                                                                        .leftDashboardOptions
                                                                                }
                                                                                positionStyle={{
                                                                                    marginRight: 30,
                                                                                }}
                                                                                onLiveModeChanged={
                                                                                    this
                                                                                        .onLiveModeChanged
                                                                                }
                                                                                onToggleClicked={
                                                                                    this
                                                                                        .onToggleClicked
                                                                                }
                                                                            />
                                                                        </FindingHandler>
                                                                    </SectionVisit>
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }
                                            )}
                                            {
                                                /* Without this condition the 404 page will be briefly rendered before the
                                                routes are loaded even if the URL is valid */
                                                this.state.routes.length !==
                                                    0 && (
                                                    <Route
                                                        path="*"
                                                        component={Page404}
                                                    />
                                                )
                                            }
                                        </Switch>
                                    </BrowserRouter>
                                </main>
                            </IdleTimer>
                        </div>
                    </DndProvider>
                </StoreContext.Provider>
            </GlobalContext.Provider>
        );
    }
}

export default AppWrapper;
