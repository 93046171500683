import React, { useEffect, useState, useCallback } from "react";
import { useDrop } from "react-dnd";
import { observer } from "mobx-react";
import MetricsTable from "../MetricsTable";
import MapComponent from "../MapComponent";
import sections from "sections.json";
import ViewOption from "../ViewOption";
import { LeadersLaggersFinding } from "common/Finding";
import CanvasTreeStore from "modules/canvas_page/CanvasTreeStore";
import Variables from "common/Variables";
import {
    ColumnFormatType,
    NumberFormatType,
    getPrettyPrintFormatValue,
    ColumnFormat,
    CanvasElement,
    CanvasDashboard,
} from "common/Canvas";
import { getLongestStringIndex } from "./utils";
import { getTextSizeWithParent } from "common/utilities/MeasureText";
import remoteModuleId from "common/remoteModuleId";
import PopupData from "common/PopupData";
import { getRawDataApi } from "common/DataApi";
import { toInteger } from "lodash";

interface Props {
    data: LeadersLaggersFinding["content"]["data"];
    config: LeadersLaggersFinding["config"];
    canvasTreeStore?: CanvasTreeStore;
    scale: number;
    disableDashboardDrag: (disable: boolean) => void;
    preview?: boolean;
    editable?: boolean;
    dataSetMenuIsOpen?: boolean;
    columnDragActive?: boolean;
    onChangeData?: (
        data: LeadersLaggersFinding["content"]["data"],
        updateFinding?: boolean
    ) => void;
    onChangeConfig?: (
        config: LeadersLaggersFinding["config"],
        updateFinding?: boolean
    ) => void;
    disableColumnResizing?: boolean;
    dashboardId?: string;
    currentModuleId?: string;
    onExpandCard?: (node: CanvasDashboard, 
        popup: {popupStatus: string, popupMessage: string} | undefined) => void;
    live?: boolean;
}

function LeadersLaggers(props: Props) {

    const { config, onChangeConfig } = props;
    const { leaders, laggers, showMap, geographyLevels, units } = props.data;
    let {
        dataScope,
        labelMapping,
        colorMapping,
        fontStyle,
        aliaseTextColor,
        variableAliases,
        targetVariableIndex,
        targetVariableFormat,
        shownVariableIndices,
        shownVariableFormats,
        shownVariableSubTitles,
    } = config ?? {};
    let defaultFirstKey = "Leaders";
    let defaultFirstColor = "#39F";
    let defaultSecondKey = "Laggers";
    let defaultSecondColor = "#EE423D";
    let viewOption = config?.viewOption ?? ViewOption.Both;
    const [columnsWidth, setColumnsWidth] = useState<{
        [key: string | number]: number;
    } | null>(config.columnsProps?.width ?? null);

    const dashboard =
        props.dashboardId != null && props.canvasTreeStore != null
            ? props.canvasTreeStore.dashboardsState.get(props.dashboardId)
            : undefined;

    let dataVariables = Variables(
        dataScope?.value,
        props.currentModuleId ?? remoteModuleId
    ).dataVariables;

    const ref = React.useRef<HTMLDivElement>(null);

    const fitToContent = useCallback(
        (key: string | number, index: number) => {
            let format: ColumnFormat = (index === -1
                ? targetVariableFormat
                : shownVariableFormats?.[index]) ?? {
                numberType: NumberFormatType.Number,
                decimals: 2,
            };
            let columnIndex =
                index === -1
                    ? targetVariableIndex
                    : shownVariableIndices[index];
            let columnName =
                variableAliases[columnIndex] ??
                dataVariables[columnIndex]?.name;
            let columnSubTitle =
                (index === -1
                    ? config.targetVariableSubTitle
                    : shownVariableSubTitles[index]) ?? "";
            let strings: string[] = [columnName, columnSubTitle]
                .concat(
                    (leaders ?? []).map((row) =>
                        getPrettyPrintFormatValue(
                            {
                                metric: String(row[key as number] ?? ""),
                                value: row[key as number],
                            },
                            format
                        )
                    )
                )
                .concat(
                    (laggers ?? []).map((row) =>
                        getPrettyPrintFormatValue(
                            {
                                metric: String(row[key as number] ?? ""),
                                value: row[key as number],
                            },
                            format
                        )
                    )
                );
            if (ref.current != null && strings.length !== 0) {
                const longestStringIndex = getLongestStringIndex(strings);
                // + 2 because of borders
                let newWidth =
                    getTextSizeWithParent(
                        strings[longestStringIndex],
                        (longestStringIndex === 0
                            ? fontStyle?.title?.fontFamily
                            : fontStyle?.text?.fontFamily) ?? "Roboto",
                        (longestStringIndex === 0
                            ? fontStyle?.title?.fontSize
                            : fontStyle?.text?.fontSize) ?? 12,
                        "normal",
                        ref.current
                    ).width + 2;

                setColumnsWidth((columnsWidth) => ({
                    ...columnsWidth,
                    [key]: newWidth,
                }));
                let newConfig = {
                    ...config,
                    columnsProps: {
                        ...(config?.columnsProps ?? {}),
                        width: {
                            ...columnsWidth,
                            [key]: newWidth,
                        },
                    },
                };
                onChangeConfig?.(newConfig, true);
            }
        },
        [
            setColumnsWidth,
            config,
            onChangeConfig,
            columnsWidth,
            fontStyle,
            laggers,
            leaders,
            shownVariableFormats,
            targetVariableFormat,
            shownVariableSubTitles,
            targetVariableIndex,
            dataVariables,
            shownVariableIndices,
            variableAliases,
        ]
    );
    
    useEffect(() => {
        if (
            !props.preview &&
            config?.shownVariableIndices != null &&
            config.shownVariableIndices.length !== 0
        ) {
            if (
                shownVariableFormats == null ||
                shownVariableFormats.length === 0
            ) {
                let newShownVariableFormats = [];
                for (
                    let i = 0;
                    i < (config?.shownVariableIndices ?? []).length;
                    i++
                ) {
                    newShownVariableFormats.push({
                        numberType: NumberFormatType.Number,
                        decimals: 2,
                    });
                }
                let newConfig = {
                    ...config,
                    shownVariableFormats: newShownVariableFormats,
                };
                onChangeConfig?.(newConfig, true);
            }
            if (
                shownVariableSubTitles == null ||
                shownVariableSubTitles.length === 0
            ) {
                let newShownVariableSubTitles = [];
                for (
                    let i = 0;
                    i < (config?.shownVariableIndices ?? []).length;
                    i++
                ) {
                    newShownVariableSubTitles.push("");
                }
                let newConfig = {
                    ...config,
                    shownVariableSubTitles: newShownVariableSubTitles,
                };
                onChangeConfig?.(newConfig, true);
            }
        }
    }, [
        shownVariableFormats,
        shownVariableSubTitles,
        config,
        props.preview,
        onChangeConfig,
    ]);

    const drop = useDrop({
        accept: "variable_column",
        drop(
            otherItem: {
                content: {
                    variableName: string;
                    variableIndex: number;
                };
            },
            monitor
        ) {
            if (props.onChangeConfig != null) {
                const variableOptionType = Variables(
                    dashboard?.finding?.config.dataScope.value,
                    props.currentModuleId
                ).variableOptions.find(
                    (option) => option.label === otherItem.content.variableName
                )!.type;

                let format: {
                    numberType: NumberFormatType;
                    decimals: number;
                    type?: ColumnFormatType;
                    format?: string;
                } = {
                    numberType:
                        variableOptionType === "float"
                            ? NumberFormatType.Number
                            : 0,
                    decimals: 2,
                };

                switch (variableOptionType) {
                    case "datetime":
                        format = {
                            ...format,
                            type: ColumnFormatType.Date,
                            format: "%m/%d/%y",
                        };
                        break;
                }

                if (props.config.targetVariableIndex == null) {
                    let config = {
                        ...props.config,
                        targetVariableSubTitle: "",
                        targetVariableFormat: format,
                        targetVariableIndex: otherItem.content.variableIndex,
                    };
                    props.onChangeConfig(config, true);
                } else {
                    let shownVariableIndices =
                        props.config.shownVariableIndices ?? [];
                    shownVariableIndices = [
                        ...shownVariableIndices,
                        otherItem.content.variableIndex,
                    ];

                    let shownVariableFormats =
                        props.config.shownVariableFormats ?? [];
                    shownVariableFormats = [...shownVariableFormats, format];
                    let shownVariableSubTitles =
                        props.config.shownVariableSubTitles ?? [];
                    shownVariableSubTitles = [...shownVariableSubTitles, ""];
                    let config = {
                        ...props.config,
                        shownVariableIndices,
                        shownVariableSubTitles,
                        shownVariableFormats,
                    };
                    props.onChangeConfig(config, true);
                }
            }
        },
        collect(monitor) {
            return { hover: monitor.isOver() };
        },
    })[1];
    if (props.editable) {
        drop(ref);
    }

    async function onRowClicked(rowData: any) {    
        if(!props.live)
            return

        if(dashboard?.finding?.config?.navigation) {
            if(!dashboard?.finding.config.navigation.column || !dashboard?.finding.config.navigation.popupSlide)
                return
        } else {
            return
        }

        PopupData.assignPopupdata({
            id:rowData[dashboard?.finding?.config?.navigation?.column?.value],
            row_id: rowData["row_id"]
        });
        let newDashboard: any = dashboard
        if(newDashboard) {
            if(!dashboard?.finding?.config?.navigation?.internal) {
                const tableData = await getRawDataApi(
                    {
                        label: "",
                        value: [],
                        optimized: false,
                        data_table_idx: dashboard?.finding?.config.dataScope.value,
                    },
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [rowData["row_id"]],
                    true
                );

                let idx = tableData.currentLevels[dashboard?.finding?.config?.navigation?.column?.label]?.
                    indexOf(rowData[dashboard?.finding?.config?.navigation?.column?.value]); 
                let link = tableData.currentLevels[dashboard?.finding?.config?.navigation?.externalLinkColumn?.label][idx];
                newDashboard['externalLink'] = link;
                newDashboard['external'] = true;
            } else {
                newDashboard['external'] = false;
                newDashboard['linkPopups'] = [
                    dashboard?.finding?.config?.navigation?.popupSlide?.value
                ];
                newDashboard['links'] = [];    
            }
        }
        props.onExpandCard?.(dashboard, undefined)
    }

    return (
        <div
            ref={ref}
            className="my-row"
            style={{
                overflow: "auto",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                position: "relative",
            }}
        >
            {showMap && (
                <>
                    <div />
                    <div
                        className="flex-simple-column"
                        style={{
                            borderRadius: "8px",
                            border: "1px solid #242E42",
                            width: "50%",
                            maxHeight: 350,
                        }}
                    >
                        <MapComponent
                            leadersColor={
                                colorMapping?.[defaultFirstKey] ??
                                defaultFirstColor
                            }
                            laggersColor={
                                colorMapping?.[defaultSecondKey] ??
                                defaultSecondColor
                            }
                            geographyLevels={geographyLevels}
                            leaders={leaders}
                            laggers={laggers}
                            height={230}
                            watermark={sections._Global?.watermark}
                        />
                    </div>
                </>
            )}
            {!showMap && <div />}
            <div
                className="flex-simple-column element"
                style={{
                    width: "100%",
                    marginLeft: 20,
                    height: "100%",
                    overflow: "auto",
                }}
            >
                {(viewOption === ViewOption.Both ||
                    viewOption === ViewOption.Leaders) && (
                    <MetricsTable
                        onRowClick={(data) => {
                            onRowClicked(data)
                        }}
                        data={leaders}
                        live={props.live}
                        config={config}
                        disableDashboardDrag={props.disableDashboardDrag}
                        title={labelMapping?.[defaultFirstKey] ?? "Top"}
                        color={
                            colorMapping?.[defaultFirstKey] ?? defaultFirstColor
                        }
                        aliaseTextColor={
                            aliaseTextColor?.[defaultFirstKey] ??
                            defaultFirstColor
                        }
                        aliaseTextSize={fontStyle?.aliase?.fontSize}
                        fontStyle={fontStyle}
                        onColorChange={(color) => {
                            if (onChangeConfig != null) {
                                onChangeConfig({
                                    ...config,
                                    colorMapping: {
                                        ...colorMapping,
                                        [defaultFirstKey]: color,
                                    },
                                });
                            }
                        }}
                        onTitleChange={(title) => {
                            if (onChangeConfig != null) {
                                onChangeConfig({
                                    ...config,
                                    labelMapping: {
                                        ...labelMapping,
                                        [defaultFirstKey]: title,
                                    },
                                });
                            }
                        }}
                        onChangeConfig={onChangeConfig}
                        units={units}
                        scale={props.scale}
                        editable={props.editable}
                        columnsWidth={columnsWidth}
                        setColumnsWidth={setColumnsWidth}
                        fitToContent={fitToContent}
                        currentModuleId={props.currentModuleId}
                    />
                )}
                {(viewOption === ViewOption.Both ||
                    viewOption === ViewOption.Laggards) && (
                    <MetricsTable
                        live={props.live}
                        onRowClick={(data) => {
                            onRowClicked(data)
                        }}
                        data={laggers}
                        config={config}
                        disableDashboardDrag={props.disableDashboardDrag}
                        title={labelMapping?.[defaultSecondKey] ?? "Bottom"}
                        color={
                            colorMapping?.[defaultSecondKey] ??
                            defaultSecondColor
                        }
                        aliaseTextColor={
                            aliaseTextColor?.[defaultSecondKey] ??
                            defaultSecondColor
                        }
                        aliaseTextSize={fontStyle?.aliase?.fontSize}
                        fontStyle={fontStyle}
                        onTitleChange={(title) => {
                            if (onChangeConfig != null) {
                                onChangeConfig({
                                    ...config,
                                    labelMapping: {
                                        ...labelMapping,
                                        [defaultSecondKey]: title,
                                    },
                                });
                            }
                        }}
                        onColorChange={(color) => {
                            if (onChangeConfig != null) {
                                onChangeConfig({
                                    ...config,
                                    colorMapping: {
                                        ...colorMapping,
                                        [defaultSecondKey]: color,
                                    },
                                });
                            }
                        }}
                        onChangeConfig={onChangeConfig}
                        units={units}
                        scale={props.scale}
                        editable={props.editable}
                        columnsWidth={columnsWidth}
                        setColumnsWidth={setColumnsWidth}
                        fitToContent={fitToContent}
                        currentModuleId={props.currentModuleId}
                    />
                )}
            </div>
            {!showMap && <div />}
            {props.columnDragActive && (
                <div
                    style={{
                        right: 0,
                        top: 0,
                        width: "50%",
                        height: "100%",
                        position: "absolute",
                        display:
                            props.editable &&
                            props.dataSetMenuIsOpen &&
                            !props.preview
                                ? "flex"
                                : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "dashed 3px #8DB8E3",
                        backgroundColor: "#EBF2F9AA",
                    }}
                >
                    <span
                        className="no-selection"
                        style={{
                            color: "#333",
                            fontSize: "14px",
                            fontWeight: 500,
                        }}
                    >
                        Drop variable here
                    </span>
                </div>
            )}
        </div>
    );
}

export default observer(LeadersLaggers);
